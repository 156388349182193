import { createRouter, createWebHistory } from 'vue-router'
import LoginForm from '@/components/LoginForm.vue'
import NotFound from '@/components/NotFound.vue'
import WelcomePage from '@/components/WelcomePage.vue'
import MainView from '@/views/MainView.vue'
import store from '@/store'
import { checkUserKey } from '../services/utils.js'
const routes = [
  {
    path: '/',
    alias: '/login',
    name: 'Вход в систему',
    component: LoginForm,
  },
  {
    path: '/index.html',
    alias: '/login',
    name: 'Вход в систему',
    component: LoginForm,
  },
  {
    path: '/welcome/:id',
    name: 'welcomePage',
    component: WelcomePage,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   meta: { auth: true },
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
  {
    path: '/main',
    name: 'Главная',
    component: MainView,
    meta: { auth: true },
    children: [
      {
        path: '/main',
        name: 'workFlow',
        component: () => import('../views/WorkFlow.vue'),
        meta: { auth: true },
      },
      {
        path: '/clients',
        name: 'clients',
        component: () => import('../views/ShowCase.vue'),
        meta: { auth: true },
      },
      {
        path: '/leads',
        name: 'leads',
        component: () => import('../views/LeadsView.vue'),
        meta: { auth: true },
      },
      {
        path: '/leads/:id',
        name: 'lead',
        component: () => import('../views/LeadView.vue'),
        meta: { auth: true },
      },
      {
        path: '/offers',
        name: 'offers',
        component: () => import('../views/OffersView.vue'),
        meta: { auth: true },
      },
      {
        path: '/offers/:id',
        name: 'offer',
        component: () => import('../views/OfferView.vue'),
        meta: { auth: true },
      },
      {
        path: '/companies',
        name: 'companies',
        component: () => import('../views/CompaniesView.vue'),
        meta: { auth: true },
      },
      {
        path: '/companies/:id',
        name: 'company',
        component: () => import('../views/CompanyView.vue'),
        meta: { auth: true },
      },
      {
        path: '/users',
        name: 'users',
        component: () => import('../views/UsersView.vue'),
        meta: { auth: true },
      },
      {
        path: '/users/:id',
        name: 'user',
        component: () => import('../views/UserView.vue'),
        meta: { auth: true },
      },
      {
        path: '/events',
        name: 'events',
        component: () => import('../views/EventsView.vue'),
        meta: { auth: true },
      },
      {
        path: '/404',
        name: '404',
        component: NotFound,
      },
      {
        path: '/:pathMatch(.+)+',
        redirect: '/404',
      },
      {
        path: '/',
        redirect: '/main',
        meta: { auth: true },
      },
    ],
  },
]
let history = createWebHistory()
const router = createRouter({
  history,
  routes,
})

router.beforeEach((to, from, next) => {
  let authorized = false
  if (store.getters.isLoggedIn || (JSON.parse(window.localStorage.getItem('vuex')) && JSON.parse(window.localStorage.getItem('vuex')).token)) {
    authorized = true
  }
  const requiresAuth = to.matched.some((r) => r.meta.auth)

  if ((to.fullPath === '/' || to.fullPath === '/login') && authorized) {
    next('/main')
    return
  }
  if (requiresAuth && !authorized) {
    next('/login')
    return
  }
  if (JSON.stringify(to.fullPath) === JSON.stringify(from.fullPath)) {
    next(false)
    return
  }
  if (typeof store.getters['getWebHistory'](-1) === 'string') {
    next()
    return
  }
  const lastNearest = store.getters['getWebHistory']().findLast((note) => note && note.fullPath && note.path === to.path && !note.fullPath.includes(to.path + '/'))
  // && !(note.fullPath.indexOf(to.path)) && !(note.fullPath.includes(to.path + '/')));
  // console.log('to.query -', to.query);
  if (lastNearest) {
    to.query = { ...JSON.parse(JSON.stringify(lastNearest)).query, ...to.query }
    // console.log('to.query +', to.query);
  }
  if (authorized && checkUserKey('agency') && to.fullPath === '/main') {
    next('/clients')
    return
  }

  const isPathClientId = /\/clients\/[a-z0-9]+$/.test(to.redirectedFrom?.path || '')
  if (to.path === '/404' && isPathClientId) {
    next('/clients')
  }
  if (to.path === '/clients' && isPathClientId) {
    to.query.clientId = to.redirectedFrom.path.split('/clients/')[1]
  }
  next()
})
router.afterEach(({ fullPath, path, name, query, params }, from) => {
  store.dispatch('updateWebHistory', { fullPath, path, name, query, params })
  // console.log('after.fullpath', store.getters.getWebHistory([-5, -1]).map(i => i.fullPath));
  // console.log('after.query', store.getters.getWebHistory(-1).query);
})
export default router
