import { ElMessage } from 'element-plus'
import { ElNotification } from 'element-plus'
import store from '../store'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import weekday from 'dayjs/plugin/weekday'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/ru'
import { tagsChanges } from '@/config'
dayjs.locale('ru')
dayjs /*configure dayjs*/
  .extend(customParseFormat)
  .extend(weekday)
  .extend(relativeTime)
  .extend(localizedFormat)

const setStorageItem = (field, str) => window.localStorage.setItem(field, str)

const getStorageItem = (field) => {
  if (JSON.parse(localStorage.getItem('settings')[field])) alert('fdsd')
}

const mapEntity = (e) => {
  return {
    leads: 'Лид',
    users: 'Пользователь',
    companies: 'Компания',
    offers: 'Oффер',
  }[e]
}

const getEntityName = (list, id, field = 'name') => {
  const obj = list.find((e) => id === e._id)
  if (obj) return obj[field]
  return ''
}

const getKeyLikeField = (list, id, field = 'name') => {
  const obj = list.find((e) => id.startsWith(e._id))
  if (obj) return obj[field]
  return ''
}
const notEmptyObj = (obj) => {
  return typeof obj !== 'undefined' && Object.keys(obj).length !== 0
}

const checkStr = (reg, str) => reg.test(str)

function debounce(callee, timeoutMs) {
  return function perform(...args) {
    let previousCall = this.lastCall

    this.lastCall = Date.now()

    if (previousCall && this.lastCall - previousCall <= timeoutMs) {
      clearTimeout(this.lastCallTimer)
    }

    this.lastCallTimer = setTimeout(() => callee(...args), timeoutMs)
  }
}

async function copyText(text) {
  try {
    await navigator.clipboard.writeText(text)
    await ElMessage({
      type: 'success',
      message: 'Cкопировано',
    })
  } catch (err) {
    console.error('Error in copying text: ', err)
  }
}

async function notifyWrap(obj) {
  try {
    await ElNotification({
      title: 'Произошла ошибка',
      message: obj.response?.data?.messageClient || obj.messageClient || obj.response?.data.message || obj.message || obj,
      type: 'error',
    })
  } catch (err) {
    console.error('Error in copying text: ', err)
  }
}

function getSelectOptions(subj, field, action = 'update') {
  const user = store.getters.getUser
  const obj = user.roleId?.rules.find((e) => e.subject.includes(subj) && e.action.includes(action))
  return obj?.conditions[field].$in
}

function checkUserKey(key) {
  const user = store.getters.getUser
  const k = user?.roleId?.key?.split('-')[0]
  return k === key
}

function checkField(subj, field, action, el) {
  const user = store.getters.getUser
  const obj = user.roleId?.rules.find((e) => e.subject.includes(subj) && e.action.includes(action) && e.hasOwnProperty('conditions'))
  return Array.isArray(obj?.conditions[field]?.$in) ? obj?.conditions[field]?.$in.some((e) => e === el) : false
}

function convertDate(dates, fromURL = false) {
  // преобразует временной диапазон из массива строк в строку для запроса и наоборот
  if (!(Array.isArray(dates) && dates[0] && dates[1])) return []

  if (fromURL) return dates.map((date) => new Date(date.slice(4)))

  const from = (dates[0]?.toISOString?.() || dates[0])?.split('T')[0]
  const to = (dates[1]?.toISOString?.() || dates[1])?.split('T')[0]

  return [`gte:${from}`, `lte:${to}`]
}

function getInitWidth(sizes) {
  if (window.innerWidth < sizes[0][1]) return 'xs'
  else return sizes.findLast((size) => window.innerWidth >= size[1])[0]
}

/**
 * Формирует объект для настройки параметров элемента фильтра.
 * Параметр только один, объект с полями.
 * * поле {String} type - Тип элемента фильтра [search, date, class, tag]
 * * поле {String} field - Соответствующее данной настройке поле в объекте PageFilters
 * * поле {Array} selectParams - (требуется для type === 'class' и type === 'tag') Строка настроки элемента select, значения разделены пробелами
 *     - 'no-multiple' - присваивает свойству multiple значение false, без него - true
 *     - 'no-filter' - (при type === 'tag') присваивает свойству filterable значение false, без него - true
 * * поле {Array} values - (требуется для type === 'class'; при type === 'tag' отключает запрос на сервер) Массив значений для el-option
 * * поле {Array} col - (требуется при type === 'tag') Поле для URL запроса
 * возможность выбора нескольких значений, нужно ли подгружать и фильтровать новый список на oninput), (optional) возможность создания нового значения.
 * Не нужен для элементов других типов. [0: [Object{_id, name}, ...], 1: Boolean, 2: Boolean]
 * * поле {String} [label] - (optional) Label для элемента фильтрации
 * По умолчанию (по порядку значений type): ['Поиск','Диапазон дат','Статус','Компания']
 * * поле {String} [placeholder] - (optional) Placeholder для элемента фильтрации
 * По умолчанию (по порядку значений type): ['8 666 13 66 00',undefined,'Выбрать','Выбрать']
 * * поле {Object} [layout] - (optional) Объект для изменения размера элемента формы.
 * По умолчанию: {xs:24, sm:12, md:8, lg:7, xl:6}
 * * поле {Boolean} [ability] - (optional) Отобразится ли элемент, по умолчанию true
 */
function getFilterOpts({
  //
  type,
  field,
  selectParams,
  values,
  col,
  label,
  placeholder,
  layout,
  ability,
}) {
  const labels = {
    search: 'Поиск',
    date: 'Диапазон дат',
    select: 'Статус',
    // class: "Статус",
    // tag: "Компания",
  }
  const placeholders = {
    search: '8 666 13 66 00',
    status: 'Выбрать',
    // class: "Выбрать",
    // tag: "Выбрать",
  }
  const filterOpts = {
    type,
    field,
    label: label || labels[type],
    placeholder: placeholder || placeholders[type],
    layout: { xs: 24, sm: 12, md: 8, lg: 7, xl: 6, ...layout },
    ability: typeof ability === 'boolean' ? ability : true,
  }
  let selectArr = selectParams?.split(' ') || []
  if (selectArr.length || values || col)
    filterOpts.selectOpts = {
      values,
      multiple: !selectArr.includes('no-multiple'),
      filterable: !selectArr.includes('no-filter'),
      col,
    }
  return filterOpts
}

function getColumnOpts({
  //
  col = 'col',
  field = '',
  name = '',
  mobName = '',
  pseudo, // псевдоним для использования в раскладке для мобильной версии
  w,
  mw = 100,
  sortable = false,
  simpleSort = false,
  ability = true,
  check = () => true,
  mobOnly = false,
  info = '',
}) {
  return {
    col,
    field,
    name,
    mobName,
    pseudo,
    w,
    mw,
    sortable,
    simpleSort,
    ability,
    check,
    mobOnly,
  }
}

function mapObject(obj, cb, keyAccess) {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => {
      return !keyAccess ? [k, cb(v)] : cb(k, v)
    })
  )
}

function switchObjEntries(obj) {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => {
      if (typeof v === 'string') return [v, k]
      return [JSON.stringify(v), k]
    })
  )
}

function filterObject(obj, cb) {
  return Object.fromEntries(
    Object.entries(obj).filter(([k, v]) => {
      return !!cb(k, v)
    })
  )
}

function cloneByJSON(obj) {
  return JSON.parse(JSON.stringify(obj))
}

function getNestedField(obj, field) {
  // доступ к вложенным свойствам объекта через строку вида 'str1['sss'].str2[2].str3'
  if (typeof field !== 'string') return
  const fieldArr = field.split(/\.|\[|\]\.?/).filter((f) => !!f)
  return fieldArr.reduce((result, curr) => result?.[curr], obj)
}

function convertToArray(v) {
  // return !v ? [] : (!Array.isArray(v)) ? [v] : v
  if (!v) return []
  if (!Array.isArray(v)) return [v]
  return v
}

function compareByOrder(list, ...args) {
  const calc = (v1, v2) => {
    const [idx1, idx2] = [list.indexOf(v1), list.indexOf(v2)]
    // if (idx1 === -1 || idx2 === -1) return 0
    if (idx1 === -1) return 1
    if (idx2 === -1) return -1
    return idx1 - idx2
  }
  if (args[0] && args[1]) return calc(args[0], args[1])
  return (v1, v2) => {
    return calc(v1, v2)
  }
}

function isNotEmptyHTML(str) {
  return /^<\w+>.+<\/\w+>$/.test(str)
}

function getWordForm(value, by) {
  const wordForms = {
    // words by value: [
    //      rem10: 1,
    //      rem10: [2-4],
    //      rem10: [5-0] or rem100: [11-14]
    // ]

    // год: ['год', 'года', 'лет'],
    // месяц: ['месяц', 'месяца', 'месяцев'],
    // неделя: ['неделя', 'недели', 'недель'],
    // день: ['день', 'дня', 'дней'],
    // час: ['час', 'часа', 'часов'],
    // минута: ['минута', 'минуты', 'минут'],
    // секуда: ['секуда', 'секунды', 'секунд'],
    клиент: ['клиент', 'клиента', 'клиентов'],
  }
  let rem100 = value % 100
  let rem10 = value % 10
  if ((rem100 >= 11 && rem100 <= 14) || (rem10 >= 5 && rem10 <= 9) || rem10 === 0) return wordForms[by][2]
  if (rem10 >= 2 && rem10 <= 4) return wordForms[by][1]
  return wordForms[by][0]
}

function sortByNumber(arr) {
  const getNumericValue = (str) => {
    return +str.match(/\d+\.?\d*/) || 0
  }
  return arr.sort((v1, v2) => getNumericValue(v1) - getNumericValue(v2))
}

function getFormattedPrice(price = 0, accuracy = 0) {
  return new Intl.NumberFormat('ru', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: accuracy,
  }).format(price)
}

function getFormattedDate(date, format = 'LL') {
  if (date === undefined) return
  return dayjs(date).format(format)
}

function getConvertedTags(field, tag, newToOld = false) {
  const changes = {
    rooms: {
      translate: tagsChanges.rooms,
      oldToNew(tag) {
        return this.translate[tag.trim()] || tag.trim()
      },
      newToOld(tag) {
        return switchObjEntries(this.translate)[tag.trim()] || tag.trim()
      },
    },
    prices: {
      oldToNew(tag) {
        const countOfNumbers = tag.match(/\d+/g).length
        const isMore = /^более /.test(tag)
        if (!isMore && countOfNumbers === 1 && !tag.trim().startsWith('до ')) return 'до ' + tag.trim()
        return tag.trim()
      },
      newToOld(tag) {
        const transform = (str) => {
          if (!str.startsWith('до ')) return str
          return transform(str.replace(/^до /, ''))
        }
        return transform(tag.trim()).trim()
      },
    },
  }

  if (field === 'isTransfer') console.log(field, tag, (newToOld = false))
  return newToOld ? changes[field]?.newToOld(tag) || tag.trim() : changes[field]?.oldToNew(tag) || (typeof tag === 'string' ? tag.trim() : tag)
}

export {
  //
  dayjs,
  notifyWrap,
  setStorageItem,
  mapEntity,
  notEmptyObj,
  checkStr,
  getEntityName,
  getKeyLikeField,
  debounce,
  copyText,
  getSelectOptions,
  checkUserKey,
  checkField,
  convertDate,
  getInitWidth,
  getFilterOpts,
  getColumnOpts,
  mapObject,
  switchObjEntries,
  filterObject,
  cloneByJSON,
  getNestedField,
  convertToArray,
  compareByOrder,
  isNotEmptyHTML,
  getWordForm,
  sortByNumber,
  getFormattedPrice,
  getFormattedDate,
  getConvertedTags,
}
