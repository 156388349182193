import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import api from '@/services/api'
import { initState, layoutSizing } from '@/config'
import { getInitWidth } from '@/services/utils.js'

const getDefaultState = () => {
  return {
    token: '',
    user: {},
    companies: [],
    users: [],
    offers: [],
    leads: [],
    clients: [],
    loadState: false,
    regions: [],
    developers: [],
    speeches: [],
    isMobileSize: false,
    viewportSize: getInitWidth(layoutSizing), // xs, sm, md, lg, xl
    webHistory: [],
    events: [],
    notifies: [],
    filters: {
      companies: {},
      users: {},
      offers: {},
      leads: {},
      clients: {},
      events: {},
    },
  }
}

export default createStore({
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    isLoggedIn: (state) => {
      return state.token
    },
    getUser: (state) => {
      return state.user
    },
    getRegions: (state) => {
      return state.regions
    },
    getDevelopers: (state) => {
      return state.developers
    },
    getLoadState: (state) => {
      return state.loadState
    },
    getWindowSizeState: (state) => {
      return state.isMobileSize
    },
    getViewportSize: (state) => (size) => {
      if (typeof size === 'string') {
        return state.viewportSize === size
      }
      return size.some((curr) => state.viewportSize === curr)
    },
    getList: (state) => (col) => {
      return state[col]
    },
    getFilters: (state) => (col) => {
      return state.filters[col]
    },
    getSpeech: (state) => (id) => {
      return state.speeches.find(({ hash }) => hash === id)
    },
    initPage: (state) => (type, id) => {
      if (id === 'new') {
        return JSON.parse(JSON.stringify(initState[type]))
      }
      return state[type].find((e) => id === e._id)
    },
    getWebHistory: (state) => (idx) => {
      const webHistoryArray = Array.from(state.webHistory)
      if (idx === undefined) return webHistoryArray
      if (Array.isArray(idx)) {
        idx = idx.map((i) => (i < 0 ? webHistoryArray.length + i : i))
        return webHistoryArray.slice(idx[0], idx[1] + 1)
      }
      return idx >= 0 ? webHistoryArray[idx] : webHistoryArray[webHistoryArray.length + idx]
    },
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_USER_AVATAR: (state, imgURL) => {
      state.user.image = imgURL
    },
    SET_BALANCE: (state, data) => {
      const i = state.companies.findIndex((c) => c._id === data.companyId)
      state.companies[i].balance = data.balance
    },
    SET_SUP_DATA: (state, { f, data }) => {
      state[f] = data
    },
    SET_LOAD_STATE: (state, value) => {
      //state.loadState = !state.loadState;
      state.loadState = value
    },
    RESET: (state) => {
      Object.assign(state, getDefaultState())
    },
    SET_DATA_LIST: (state, { col, data }) => {
      if (!Array.isArray(data)) {
        const i = state[col].findIndex((e) => e._id === data._id)
        state[col].some((e) => data._id === e._id) ? (state[col][i] = data) : state[col].push(data)
      } else {
        const modData = data.map((l) => {
          return {
            ...l,
            editMode: false,
            col: col,
          }
        })
        state[col] = modData
      }
    },
    SET_FILTER_LIST: (state, { col, data }) => {
      if (!data || !Object.keys(data).length) return
      state.filters[col] = data
    },
    UPDATE_DATA_LIST: (state, { id, col }) => {
      state[col] = state[col].filter((e) => e._id !== id)
    },
    UPDATE_DATA_LIST_DATA: (state, { resp, col }) => {
      resp.forEach((e) => {
        state[col].forEach((i, index) => {
          if (e._id === i._id) {
            e.col = col
            state[col][index] = e
          }
        })
      })
    },
    UPDATE_OBJ: (state, { updatedObj, col }) => {
      const i = state[col].findIndex((e) => e._id === updatedObj._id)
      updatedObj.col = col
      state[col][i] = updatedObj
    },
    MUTATION_OBJ: (state, { col, obj }) => {
      if (typeof obj === 'string') {
        obj = state[col].find((e) => obj === e._id)
      }
      obj.comments = ['ПРОДАНО']
      // obj.price = 0
      // obj.records = []
      obj.blocked = true
      state[col].push(obj)
      state[col].pop()
    },
    PUSH_WEB_HISTORY: (state, newPath) => {
      state.webHistory.push(newPath)
    },
    NULL_WEB_HISTORY: (state, num) => {
      state.webHistory.find((note, i) => {
        if (i < num) {
          if (note) state.webHistory[i] = null
          return false
        }
        return true
      })
    },
    POP_WEB_HISTORY: (state) => {
      state.webHistory.pop()
    },
    PUSH_NOTE_EVENTS: (state, event) => {
      if (!state.events.map((e) => e._id).includes(event._id)) state.events.push(event)
    },
  },
  actions: {
    login: ({ commit }, { token, user, regions, developers }) => {
      commit('SET_TOKEN', token)
      commit('SET_USER', user)
      commit('SET_SUP_DATA', { f: 'regions', data: regions })
      commit('SET_SUP_DATA', { f: 'developers', data: developers })
    },
    logout: ({ commit }) => {
      commit('RESET', '')
    },
    async getDataList({ commit }, payload) {
      try {
        if (['speeches', 'regions'].every((item) => !payload.col.includes(item)) && !payload?.id?.includes('messages')) {
          commit('SET_LOAD_STATE', true)
        }
        const resp = await api.getData(payload)
        if (resp.status !== 200) throw new Error({ message: 'Данные не получены' })
        //TODO: ЗАГЛУШКА SLICE .slice(0, 10)
        if (payload.col.includes('developers') || payload.col.includes('regions')) {
          let supCol = payload.col.split('/')[1]
          commit('SET_SUP_DATA', { f: supCol, data: resp.data })
          return resp.meta
        }
        if (payload.col === 'users' && payload?.id?.includes('messages')) {
          commit('SET_SUP_DATA', { f: 'events', data: resp.data })
          return resp.meta
        }
        if (payload.col === 'events') {
          commit('SET_SUP_DATA', { f: 'notifies', data: resp.data })
          return resp.meta
        }
        commit('SET_DATA_LIST', { col: payload.col, data: resp.data })
        commit('SET_FILTER_LIST', { col: payload.col, data: resp._filters })
        return resp.meta
      } catch (e) {
        commit('SET_DATA_LIST', { col: payload.col, data: [] })
        throw e
      } finally {
        commit('SET_LOAD_STATE', false)
      }
    },
    async createOrEditEntity({ commit }, payload) {
      try {
        // commit("SET_LOAD_STATE", true);
        const resp = await api.createOrEditEntity(payload)
        commit('UPDATE_OBJ', { col: payload.col, updatedObj: resp.data })
        return resp.data
      } catch (e) {
        throw e
      } finally {
        // commit("SET_LOAD_STATE", false);
      }
    },
    async updateEntityField({ commit }, payload) {
      try {
        const resp = await api.updateEntityField(payload)
        commit('UPDATE_OBJ', { col: payload.col, updatedObj: resp.data })
        return resp.data
      } catch (e) {
        throw e
      }
    },
    async updateEntitiesField({ commit }, payload) {
      try {
        // commit("SET_LOAD_STATE", "");
        const resp = await api.updateEntitiesField(payload)
        if (resp.status !== 200) throw new Error({ message: 'Данные не получены' })
        //TODO: ЗАГЛУШКА SLICE .slice(0, 10)
        commit('UPDATE_DATA_LIST_DATA', { col: payload.col, resp: resp.data })
        return resp.meta
      } catch (e) {
        //commit("SET_DATA_LIST", { col: payload.col, data: [] });
        throw e
      } finally {
        //commit("SET_LOAD_STATE", "");
      }
    },
    async deleteEntity({ commit }, payload) {
      if (!(payload.col === 'users' && payload?.id?.includes('messages'))) {
        commit('SET_LOAD_STATE', true)
      }
      try {
        const resp = await api.deleteEntity(payload)
        commit('UPDATE_DATA_LIST', { id: resp.data._id, col: payload.col })
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOAD_STATE', false)
      }
    },
    async downloadFile({ commit }, payload) {
      try {
        return await api.getFile(payload)
      } catch (e) {
        throw e
      }
    },
    updateWebHistory({ commit, state }, route) {
      if (state.webHistory?.[state.webHistory.length - 1]?.path === route.path) {
        state.webHistory.findLast((note) => {
          let a = state.webHistory.length
          if (note?.path !== route.path) return true
          commit('POP_WEB_HISTORY')
          return false
        })
        // commit('POP_WEB_HISTORY')
      }
      commit('PUSH_WEB_HISTORY', route)
      const webHistoryLength = state.webHistory.length
      const maxLength = 100
      if (webHistoryLength > maxLength) commit('NULL_WEB_HISTORY', webHistoryLength - maxLength)
    },
  },
})
