<template >
    <div class="wrapper" :class="{ mobile: isMobileSize }">

        <div class="notifies-title">
            <div class="title">
                <h3>Уведомления</h3>
                <el-badge v-if="noteEventsComp.length" :value="noteEventsComp.length" />
            </div>
            <div class="notifies-readall">
                <el-link :underline='false' type='primary' @click="deleteNotify('all')">Прочитать все</el-link>
            </div>
        </div>
        <div class="notifies-wrapper" ref="scrollWrapper">
            <el-scrollbar noresize v-if="noteEventsComp.length" :max-height="!isMobileSize ? '500px' : '70vh'"
                @scroll="onScrollEnd">

                <div class="start" ref="scrollStart"></div>
                <div class="notify" v-for="(notify, i) in noteEventsComp" :key="i">
                    <div :underline='false'
                        @click="() => notify.link && notify?.subject !== 'Leads' ? routeToEvent(notify.link) : undefined"
                        class="text" :class="{ link: notify.link && notify?.subject !== 'Leads' }">
                        <el-badge class="dot" is-dot />
                        <h4>{{ getTitleFrase(notify.subject, notify.action) }}
                            <el-icon v-if="notify.link && notify?.subject !== 'Leads'" style="margin-right:3px">
                                <svg width="800px" height="800px" viewBox="0 0 64 64" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xml:space="preserve" xmlns:serif="http://www.serif.com/"
                                    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
                                    <rect id="Icons" x="-896" y="0" width="1280" height="800" style="fill:none;" />
                                    <g id="external-link">
                                        <path
                                            d="M36.026,20.058l-21.092,0c-1.65,0 -2.989,1.339 -2.989,2.989l0,25.964c0,1.65 1.339,2.989 2.989,2.989l26.024,0c1.65,0 2.989,-1.339 2.989,-2.989l0,-20.953l3.999,0l0,21.948c0,3.308 -2.686,5.994 -5.995,5.995l-28.01,0c-3.309,0 -5.995,-2.687 -5.995,-5.995l0,-27.954c0,-3.309 2.686,-5.995 5.995,-5.995l22.085,0l0,4.001Z" />
                                        <path
                                            d="M55.925,25.32l-4.005,0l0,-10.481l-27.894,27.893l-2.832,-2.832l27.895,-27.895l-10.484,0l0,-4.005l17.318,0l0.002,0.001l0,17.319Z" />
                                    </g>
                                </svg>
                            </el-icon>
                        </h4>
                        <p class="message">{{ notify.message }}</p>
                        <span class="time">{{ getTimeFromNow(notify._updateAt) }}</span>
                    </div>
                    <el-button class="close" text @click="deleteNotify(notify)">
                        <el-icon color="#b1b3b8" :size="20">
                            <Plus />
                        </el-icon>
                    </el-button>
                </div>
                <div class="end" ref="scrollEnd"></div>
                <!-- <div v-if="totalItems > noteEventsComp.length" class="more">
                        <el-link @click="getEvents(notifyLimitMore)" type="primary">
                            Загрузить ещё
                        </el-link>
                    </div> -->
            </el-scrollbar>
            <div class="nothing" v-else>
                <span>Новых уведомлений нет</span>

            </div>
        </div>
        <div class="allnotifies-link" @click="routeToEvent('/events')">
            <span>Журнал</span>
        </div>
    </div>
</template>
<script>
import { eventOptions } from "@/config";
import api from '@/services/api'
import { getEntityName, notifyWrap } from "@/services/utils";
import { Plus } from '@element-plus/icons-vue'
export default {
    emits: ['routeToEvents', 'updNotifyCount'],
    components: {
        Plus
    },
    data() {
        return {
            totalItems: 0,
        }
    },
    methods: {
        getTimeFromNow(date) {
            const str = this.$dayjs(date).fromNow();
            return str === 'день назад' ? 'вчера' : str;
        },
        getTitleFrase(sub, act) {
            if (sub && act) {
                const subject = getEntityName(eventOptions, sub)
                const action = getEntityName(getEntityName(eventOptions, sub, 'action'), act).toLowerCase()
                return `${subject} был${sub === 'Companies' ? 'а' : ''} ${action}`
            }
            return 'Сообщение'
        },
        async deleteNotify(notify) {
            if (notify === 'all') {
                await this.deleteEvents()
                return
            }
            await this.deleteEvents(notify._id)
        },
        async getEvents(limit) {
            const params = {};
            if (limit) params._limit = limit;
            try {
                const resp = await this.$store.dispatch('getDataList', {
                    col: 'users',
                    id: `${this.$store.getters.getUser._id}/messages`,
                    params
                });
                this.totalItems = resp._totalItems;
            } catch (e) {
                console.log(e)
                if (!this.$store.getters.isLoggedIn) return
                notifyWrap(e)
            }
        },
        async deleteEvents(notifyId) {
            const params = {
                col: 'users',
                id: `${this.$store.getters.getUser._id}/messages`
            }
            if (notifyId) params.id += `/${notifyId}`
            try {
                const resp = await api.deleteEntity(params);
                await this.getEvents(this.notifyLimit);
            } catch (e) {
                if (!this.$store.getters.isLoggedIn) return
                notifyWrap(e)
            }
        },
        onScrollEnd(dist) {
            if (this.totalItems <= this.noteEventsComp.length) return;
            // высота всех уведомлений
            let contentHeight = this.$refs.scrollEnd.getBoundingClientRect().bottom - this.$refs.scrollStart.getBoundingClientRect().bottom;
            // разница по высоте между последним уведомлением и контейнером уведомлений
            let diffHeight = this.$refs.scrollEnd.getBoundingClientRect().bottom - this.$refs.scrollWrapper.getBoundingClientRect().bottom;
            if (contentHeight - dist < 100 || diffHeight < 100) this.getEvents(this.notifyLimitMore);
        },
        routeToEvent(link) {
            console.log(link);
            this.$emit('routeToEvents')
            this.$router.push({ path: link })
        }
    },
    computed: {
        noteEventsComp() {
            return this.$store.getters["getList"]('events').slice().reverse();
        },
        isMobileSize() {
            return this.$store.getters["getWindowSizeState"];
        },
        notifyLimitMore() {
            return 50 * (Math.round(this.noteEventsComp.length / 50) + 1)
        },
        notifyLimit() {
            return 50 * Math.round(this.noteEventsComp.length / 50)
        }
    },
    watch: {
        noteEventsComp: {
            handler(nv, ov) {
                this.$emit('updNotifyCount', nv.length)
            },
            deep: true
        },
    },
    async mounted() {
        await this.getEvents();
    }
}
</script>
<style scoped>
.wrapper.mobile {
    height: 100%;
}

.wrapper:not(.mobile) {
    width: 250px;
}

h3 {
    margin: 0;
}

h4 {
    margin: 0 0 3px;
}

.mobile>.notifies-title {
    position: absolute;
    top: 20px;
    left: 20px;
}

.notify {
    padding: 10px 0 0;
    /*border-top: var(--el-border);*/
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

/*.notify:not(:last-of-type) {
    padding-bottom: 10px;
}*/

.more {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    display: flex;
}

.el-badge {
    margin-left: 5px;
}

.text {
    width: 100%;
    padding-left: 18px;
    position: relative;
}

.text h4 {
    display: inline-block;
}

.text.link:hover {
    color: var(--el-color-primary);
    cursor: pointer;
}

.link h4 {
    color: var(--el-color-primary);
}

.link:hover h4 {
    border-bottom: 1px var(--el-color-primary) solid;
}

.dot {
    position: absolute;
    left: -5px;
    top: 5px;
}

p.message {
    margin: 0;
}

.time {
    font-size: var(--el-font-size-extra-small);
}

.time:not(.text:hover .time) {
    color: var(--el-text-color-secondary);
}


.close {
    margin: 0;
    padding: 0;
    width: 35px;
    /*height: 35px;*/
    aspect-ratio: 1;
    border-radius: 50%;
    position: relative;
    top: -5px;
}

.close svg {
    transform: rotate(45deg);
}

.close:not(:hover) {
    background-color: transparent !important;
}

.nothing {
    display: flex;
    height: 70px;
    justify-content: center;
    align-items: center;
}

.nothing>span {
    color: var(--el-text-color-secondary);
}

.allnotifies-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px -12px -12px;
    height: 50px;
    border-top: var(--el-border);
    background-color: var(--el-fill-color-light);
}



.allnotifies-link>span {
    color: var(--el-color-primary)
}

.allnotifies-link:hover {
    background-color: var(--el-border-color-lighter);
    cursor: pointer;
}

.allnotifies-link:active {
    background-color: var(--el-border-color-light);
}

.mobile .allnotifies-link {
    position: absolute;
    margin: 5px -20px -20px;
    width: 100%;
    bottom: 0;
    transform: translateY(-50%);
}
</style>