<template>
    <div class="container">

        <!-- <el-alert title="success alert" type="success">
        </el-alert> -->
        <el-form :model="obj" label-width="120px" label-position="top" :rules="rules" ref="UserForm">
            <el-row :gutter="20" style="max-width: 350px;">
                <el-col :span="24" :xs="{ span: 24, offset: 0 }">
                    <el-alert :closable="false" title="Cоздайте адинистратора для своей компании" type="success"
                        style="margin: 15px 0px">
                    </el-alert>
                    <el-form-item label="Имя" prop="name">
                        <el-input v-model="obj.name" placeholder="Введите имя пользователя" />
                    </el-form-item>
                </el-col>
                <el-col :span="24" :xs="{ span: 24, offset: 0 }">
                    <el-form-item label="Электронная почта" prop="login">
                        <el-input v-model="obj.login" placeholder="Введите e-mail" />
                    </el-form-item>
                </el-col>
                <el-col :span="24" :xs="{ span: 24, offset: 0 }">
                    <el-form-item label="Пароль " prop="password">
                        <el-input v-model="obj.password" placeholder="Введите пароль" />
                    </el-form-item>
                </el-col>
                <el-col :span="24" :xs="{ span: 24, offset: 0 }">
                    <el-form-item prop="personal" class="checkbox-item">
                        <el-checkbox v-model="obj.personal">
                            <span>
                                Даю согласие на обработку персональных данных
                            </span>
                        </el-checkbox>
                    </el-form-item>
                </el-col>
                <el-col :span="24" :xs="{ span: 24, offset: 0 }">
                    <el-form-item prop="agreement" class="checkbox-item">
                        <el-checkbox v-model="obj.agreement">
                            <span>
                                Принимаю
                                <el-link href="/agreement.pdf" @click.stop target="_blank" type="primary">
                                    пользовательское соглашение
                                </el-link>
                                и условия
                                <el-link href="/offer.pdf" @click.stop target="_blank" type="primary">оферты</el-link>
                            </span>
                        </el-checkbox>
                    </el-form-item>
                </el-col>
                <el-col :span="24" :xs="{ span: 24, offset: 0 }">
                    <el-form-item class="checkbox-item">
                        <el-checkbox v-model="adAgreement">
                            <span>
                                Даю согласие на на получение уведомлений и рекламных
                                материалов
                            </span>
                        </el-checkbox>
                    </el-form-item>
                </el-col>
                <el-col :span="24" style="margin-top:4px">
                    <el-button type="info" :loading="loadState" plain v-if="loadState"></el-button>
                    <el-button type="success" :loading="loadState" plain @click="save" v-else>
                        <el-icon style="vertical-align: middle" :size="20">
                            <DocumentAdd />
                        </el-icon>
                        <span>Сохранить</span>
                    </el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<style scoped>
.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.el-form-item {
    margin-top: 4px;
}

/*.el-form-item.checkbox-item {
    margin-bottom: 4px;
}*/

.el-checkbox {
    white-space: normal;
    align-items: flex-start;
    height: auto;
}

.el-checkbox.is-checked>:deep(span>span) {
    color: var(--el-checkbox-text-color);
}

:deep(.el-checkbox__label>span),
.el-link {
    display: inline-block;
    line-height: 1.5;
    margin-top: -2px;
    font-size: 12px;
}
</style>
<script>
import { userConf } from '@/config'
import { cloneByJSON, notifyWrap } from '@/services/utils'
import { DocumentAdd } from '@element-plus/icons-vue'

export default {
    data() {
        return {
            _id: '',
            obj: {},
            userConf,
            col: 'companies',
            adAgreement: false,
            rules: {
                name: [
                    { required: true, message: 'Имя - обязательное поле', trigger: 'blur' },
                ],
                login: [
                    { required: true, message: 'Логин - обязательное поле', trigger: 'blur' },
                ],
                password: [
                    { required: true, message: 'Пароль - обязательное поле', trigger: 'blur' },
                ],
                personal: [
                    {
                        message: 'Без согласия регистрация невозможна',
                        trigger: 'change',
                        validator: (rule, value) => !!value,
                    },
                ],
                agreement: [
                    {
                        message: 'Без принятия условий регистрация невозможна',
                        trigger: 'change',
                        validator: (rule, value) => !!value,
                    },
                ],
            },
            notifyMessages: [
                { prop: 'name', title: 'Вы не ввели имя' },
                { prop: 'login', title: 'Вы не ввели логин' },
                { prop: 'password', title: 'Вы не ввели пароль' },
                { prop: 'personal', title: 'Вы не подтвердили обработку персональных данных' },
                { prop: 'agreement', title: 'Вы не дали согласие на условия оферты и пользовательское соглашение' },
            ]
        }
    },
    computed: {
        loadState() {
            return this.$store.getters['getLoadState']
        }
    },
    components: { DocumentAdd },
    methods: {
        save() {
            console.log(this.newObj)
            const options = {
                method: 'post',
                payload: cloneByJSON(this.obj),
                col: this.col,
                welcome: true
            }
            this.$refs.UserForm.validate(valid => {
                if (valid) {
                    delete options.payload.personal
                    this.$store.dispatch('createOrEditEntity', options)
                        .then(res => {
                            this.newObj = false
                            this.$notify({
                                title: 'Успешно',
                                type: 'success'
                            })
                            const path = '/login'
                            this.$router.push({ path: path, replace: true })
                        })

                        .catch(e => {
                            if (!this.$store.getters.isLoggedIn) return
                            notifyWrap(e)
                        })
                } else {
                    this.notifyMessages
                        .filter(({ prop }) => !this.obj[prop])
                        .forEach(({ title, message }) => {
                            setTimeout(() => { // без timeout уведомления накладываются друг на друга
                                this.$notify({
                                    title,
                                    message,
                                    type: 'error',
                                    duration: 5500
                                })
                            }, 0)
                        })
                    return false
                }
            });
        }
    },
    mounted() {
        this._id = this.$route.params.id
        this.obj = this.$store.getters['initPage']('users', 'new')
        this.obj.companyId = this._id
        this.obj.role = 'admin'
    }
}
</script>