<template>
  <div class="footer-block" :class="{ mobile: isMobileSize }">
    <div class="elements">
      <span>© 2023 "2Vitrina". Все права защищены.</span>
      <div class="nav-bar">
        <el-link href="/offer.pdf" target="_blank" type="primary">
          Оферта
        </el-link>
        <el-link href="/agreement.pdf" target="_blank" type="primary">
          Пользовательское соглашение
        </el-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    isMobileSize() {
      return this.$store.getters['getWindowSizeState']
    },
  },
}
</script>
<style scoped>
.footer-block {
  margin: 16px -20px -20px;
  padding: 8px 20px;
  background-color: var(--el-color-info-light-9);
}

.elements {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin: 8px 0;
  color: var(--el-text-color-placeholder);
}

.nav-bar {
  display: flex;
  gap: 16px;
}

.footer-block.mobile>.elements {
  flex-direction: column-reverse;
  justify-content: start;
  gap: 8px;
  padding-bottom: 100px;
}

/*.footer-block.mobile>.elements>.nav-bar {
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  align-self: flex-end;
}*/
</style>
