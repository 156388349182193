<template>
  <div class="container">
    <el-row :gutter="8">
      <!-- <el-col :span="12">
                <LogoSVG />
            </el-col> -->
      <el-col :span="8">
        <div class="centered">
          <LogoSVG style="transform: scale(1.3); transform-origin: bottom left" />
          <el-form label-position="top" class="form">
            <el-form-item label="Почта">
              <el-input v-model="login" placeholder="Введите почту" />
            </el-form-item>
            <el-form-item label="Пароль">
              <el-input v-model="password" type="password" placeholder="Введите пароль" show-password
                @keyup.enter="getLogin" />
            </el-form-item>
          </el-form>
          <el-button type="primary" plain @click="getLogin">Войти</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.container {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered {
  width: 10em;
}

.el-form.form :deep(label.el-form-item__label) {
  margin-bottom: 4px;
  line-height: 1.2;
}
</style>
<script>
import Api from '@/services/api.js'
import LogoSVG from '@/components/LogoSVG.vue'
import ability from '@/services/ability'

import { createMongoAbility } from '@casl/ability'
import { notifyWrap } from '@/services/utils'
export default {
  components: { LogoSVG },
  data() {
    return {
      login: '',
      password: '',
    }
  },
  methods: {
    getLogin() {
      Api.login({ login: this.login, password: this.password })
        .then((res) => {
          const { token, user, regions, developers } = res.data
          this.$store.dispatch('login', { token, user, regions, developers })
        })
        .then(() => {
          //TODO: ПОДУМАТЬ НАД DRY
          const user = this.$store.getters['getUser']
          ability(user.roleId?.rules)
          // this.$router.push({ path: '/main' })
          window.location.replace('/main')
        })
        .catch((e) => notifyWrap(e))
    },
  },
}
</script>
