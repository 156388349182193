<template>
    <template v-if="path">
        <p>{{ path }}</p>
    </template>
    <template v-else>
        <el-page-header @back="goBack">
            <template #content>
                <span class="text-large font-600 mr-3"> {{ ($route.redirectedFrom && $route.redirectedFrom.fullPath) ||
                    '404' }} </span>
            </template>
        </el-page-header>
        <p>Не найден {{ entity || '-' }}</p>
        <span v-if="$route.redirectedFrom && $route.redirectedFrom.params.pathMatch[1]">C айди
            {{ $route.redirectedFrom.params.pathMatch[1] }}</span>
    </template>
    <el-empty :image-size="200" />
</template>
<script>
import { mapEntity } from '@/services/utils'
export default {
    props: {
        path: String,
    },
    computed: {
        mapEntity(e) {
            mapEntity(e)
        }
    },
    data() {
        return {
            entity: this.$route.redirectedFrom && this.$route.redirectedFrom.params.pathMatch[0]
                ? mapEntity(this.$route.redirectedFrom.params.pathMatch[0])
                : '-'
        }
    },
    methods: {
        goBack() {
            return this.$router.go(-1)
        }
    }
}
</script>