<template>
  <el-row class="header-block">
    <div class="burger">
      <!-- <el-button v-if="$store.getters.getViewportSize(['xs', 'sm', 'md'])" class="burger-btn" text -->
      <el-button class="burger-btn" text @click="$emit('burger-click')">
        <el-icon :size="20">
          <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 12H20" stroke="#294e76" stroke-width="2" stroke-linecap="round" />
            <path d="M5 17H20" stroke="#294e76" stroke-width="2" stroke-linecap="round" />
            <path d="M5 7H20" stroke="#294e76" stroke-width="2" stroke-linecap="round" />
          </svg>
        </el-icon>
      </el-button>
      <!-- <el-icon>
                <Menu />
            </el-icon> -->
    </div>
    <!-- <el-col :xs="{ span: 24, offset: 0 }" :sm="{ span: 20, offset: 0 }" :md="{ span: 9, offset: 18 }"> -->
    <div class="user-bar">
      <!-- <el-tag class="ml-2 header-tag" type="info" size="large" effect="plain" round v-if="!isMobileSize"> -->
      <div class="balance" v-if="!checkUserKey('ad')">
        <el-icon style="vertical-align: middle" :size="15">
          <Coin />
        </el-icon>
        <div>{{ companyBalance }}</div>
      </div>
      <!-- </el-tag> -->

      <el-popover v-if="!isMobileSize" trigger="click" persistent placement="bottom-end" width="max-content">
        <template #reference>
          <el-button class="notice-btn" @click="showNoticePanel = true" text>
            <el-badge is-dot :hidden="!isNotifyComp">
              <el-icon :class="newNotifyClass" color="#b1b3b8" :size="20">
                <BellFilled />
              </el-icon>
            </el-badge>
          </el-button>
        </template>
        <NotificationsPanel @route-to-events="hideNoticePanel" @upd-notify-count="updateNotifyBadge" />
      </el-popover>
      <el-button v-if="isMobileSize" @click="() => (isMobileSize ? toggleNoticePanel() : undefined)" class="notice-btn"
        text>
        <el-badge is-dot :hidden="!isNotifyComp">
          <el-icon :class="newNotifyClass" color="#b1b3b8" :size="20">
            <BellFilled />
          </el-icon>
        </el-badge>
      </el-button>
      <el-drawer v-if="isMobileSize" v-model="showNoticePanel" direction="ltr" size="100%">
        <NotificationsPanel @route-to-events="hideNoticePanel" @upd-notify-count="updateNotifyBadge" />
      </el-drawer>
      <div class="user-info">
        <div class="text">
          <h3 @click="userView">{{ user.name }}</h3>
          <span>{{ user.roleId.name }}</span>
        </div>
        <el-dropdown placement="bottom-end">
          <div class="dropdown-trigger">
            <div class="avatar">
              <img v-if="imageUrlComp" :src="imageUrlComp" class="avatarImg" />
              <el-icon v-else style="vertical-align: middle" :size="20">
                <User />
              </el-icon>
            </div>
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </div>
          <template #dropdown>
            <!-- <div class="balance" v-if="isMobileSize">
                            <el-icon style="vertical-align: middle" :size="15">
                                <Coin />
                            </el-icon>
                            <div>{{ companyBalance }}</div>
                        </div> -->
            <el-dropdown-menu>
              <el-dropdown-item @click="userView">
                <el-icon style="vertical-align: middle">
                  <User :size="25" />
                </el-icon>Профиль
              </el-dropdown-item>
              <el-dropdown-item @click="logout">
                <el-icon style="vertical-align: middle">
                  <CloseBold :size="25" />
                </el-icon>Выйти
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </el-row>
</template>
<style scoped>
.info {
  background-color: #909399;
}

.header-block {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  z-index: 2000;
  border-bottom: var(--el-border);
  padding-bottom: 5px;
}

.user-info {
  height: 100%;
  display: flex;
  align-items: center;
}

.text {
  font-size: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--el-text-color-secondary);
}

.text h3 {
  margin: 0;
  font-size: 12px;
  color: var(--el-text-color-regular);
}

.text h3:hover {
  color: var(--el-color-primary);
  cursor: pointer;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: var(--el-border);
  border-color: var(--el-text-color-secondary);
  margin-right: 5px;
  /*background-color: var(--el-color-primary-light-9);*/
}

.avatar svg:first-of-type {
  color: var(--el-text-color-secondary);
}

.avatarImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.dropdown-trigger {
  color: var(--el-text-color-regular);
  display: flex;
  margin-left: 10px;
  align-items: center;
}

.header-tag {
  margin-right: 10px;
}

.balance {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 12px;
  color: var(--el-text-color-secondary);
  /*border-bottom: var(--el-border);*/
}

.balance>div {
  margin-left: 5px;
  text-overflow: ellipsis;
}

.user-bar {
  display: flex;
  align-items: center;
}

.header-tag .el-icon {
  margin-right: 5px;
  vertical-align: middle;
}

.burger {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notice-btn,
.burger-btn {
  margin: 0;
  padding: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.notice-btn {
  margin-right: 10px;
}

.notice-btn:not(:hover),
.burger-btn:not(:hover) {
  background-color: transparent !important;
}

/*.burger>.el-button:hover {
    background-color: #294e7613;
}

/*.burger>.el-button svg {
    margin: 7px;
}

/*.user-bar>*:not(:last-child) {
    margin-right: 10px;
}*/

.earthquake {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
</style>
<script setup>
import { ref, unref } from 'vue'
import { ClickOutside as vClickOutside } from 'element-plus'
const popoverRef = ref()
const onClickOutside = () => {
  unref(popoverRef).popperRef?.delayHide?.()
}
</script>
<script>
import { User, CloseBold, ArrowDown, Coin, DataBoard, BellFilled } from '@element-plus/icons-vue'
import io from '@/services/socket'
import { showNotification } from '@/services/notification'
import NotificationsPanel from '../components/NotificationsPanel.vue'
import { devUrl } from '@/config'
import { checkUserKey, getFormattedPrice, notifyWrap } from '@/services/utils'

export default {
  components: { User, CloseBold, ArrowDown, Coin, DataBoard, NotificationsPanel, BellFilled },
  emits: ['routeToEvents', 'updNotifyCount', 'burger-click'],
  data() {
    return {
      user: this.$store.getters.getUser,
      socket: {},
      msg: '',
      showNoticePanel: false,
      isNotify: 0,
      newNotify: false,
      checkUserKey
      // imageUrl: '',
      // заглушка для тестов
      // imageUrl: 'https://cdn.fishki.net/upload/post/2020/07/13/3367909/9caf06eba598301086fe2ed34ab4af54.jpg',
    }
  },
  computed: {
    isMobileSize() {
      return this.$store.getters['getWindowSizeState']
    },
    isNotifyComp() {
      return this.isNotify
    },
    newNotifyClass() {
      return this.newNotify ? 'earthquake' : ''
    },
    imageUrlComp() {
      return this.$store.getters['getUser'].image || ''
    },
    companyBalance() {
      const company = this.$store.getters['getList']('companies').find((c) => this.user.companyId === c._id)
      // return new Intl.NumberFormat('ru', {
      //   style: 'currency',
      //   currency: 'RUB',
      //   maximumFractionDigits: 0,
      // }).format(company?.balance || 0)
      return getFormattedPrice(company?.balance)
    },
    // ...mapGetters([
    //     'getViewportSize'
    // ])
  },
  methods: {
    logout() {
      this.$confirm('Вы хотите выйти из системы?', '', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        type: 'warning',
      })
        .then(() => {
          this.$store.dispatch('logout')
          this.$message({
            type: 'success',
            message: 'Вышли',
          })
        })
        .then(() => this.$router.push({ path: '/login' }))
        .catch(() => {
          if (!this.$store.getters.isLoggedIn) return
          this.$message({
            type: 'info',
            message: 'Действие отменено',
          })
        })
    },
    userView() {
      return this.$router.push({ path: '/users/' + this.user._id })
      //this.$router.push({path: `/user:${this.user._id}`})
    },
    updateNotifyBadge(n) {
      // console.log('update count', n);
      this.isNotify = n
    },
    hideNoticePanel() {
      // if (!this.isMobileSize) onClickOutside()
      this.showNoticePanel = false
    },
    toggleNoticePanel() {
      this.showNoticePanel = !this.showNoticePanel
    },
  },
  watch: {
    isNotify(nv, ov) {
      if (nv <= ov) return // вариант для прода
      // if (nv === ov) return; // вариант для тестов
      if (this.newNotify === true) this.newNotify = false
      this.newNotify = true
      setTimeout(() => (this.newNotify = false), 550)
    },
  },
  beforeCreate() {
    this.col = this.$route.path.match(/\/(.*)/i)
    this.$store
      .dispatch('getDataList', { col: 'companies' })
      .then(() => console.log('yes'))
      .catch((e) => {
        if (!this.$store.getters.isLoggedIn) return
        notifyWrap(e)
      })
  },
  mounted() {
    io.setupSocketConnection(this.user)
    this.isNotify = this.$store.getters['getList']('events').length
    this.$store.dispatch('getDataList', { col: 'users', id: this.user._id })
      .then(() => {
        let image = this.$store.getters['initPage']('users', this.user._id)?.image
        if (image) this.$store.commit('SET_USER_AVATAR', `${devUrl}${image}`)
      })
      .catch(err => {
        console.log(err)
        if (!this.$store.getters.isLoggedIn) return
        notifyWrap(err)
      })
  },
}
</script>
