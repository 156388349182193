import { io } from 'socket.io-client'
import store from '../store'
import { showNotification } from '@/services/notification'
const url = ''

class SocketioService {
  socket
  constructor() {}

  setupSocketConnection(u) {
    const user = { companyId: u.companyId, _id: u._id }
    this.socket = io(url, {
      auth: {
        user,
      },
    })
    console.log(`Connecti`)

    this.socket.on('message', (data) => {
      if (data.type === 'push') {
        showNotification(data)
        store.commit('PUSH_NOTE_EVENTS', data)
      }

      if (data.type === 'balance') store.commit('SET_BALANCE', data.data)

      if (data.type === 'buy') store.commit('MUTATION_OBJ', { col: 'clients', obj: data.data.clientId })
    })
  }

  subscribeToMessages(cb) {
    if (!this.socket) return true
    this.socket.on('message', (msg) => {
      console.log('Room event received!')
      return cb(null, msg)
    })
  }

  sendMessage({ message, roomName }, cb) {
    if (this.socket) this.socket.emit('message', { message, roomName }, cb)
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect()
    }
  }
}

export default new SocketioService()
