// import { switchObjEntries } from './services/utils'

// const devUrl = 'http://localhost:3000'
const devUrl = ''

const layoutSizing = [
  ['sm', 765],
  ['md', 992],
  ['lg', 1200],
  ['xl', 1920],
]

const actionsOptions = [
  {
    _id: 'delete',
    name: 'Удален',
    color: '#F56C6C',
  },
  {
    _id: 'read',
    name: 'Прочитан',
    color: '#a32cd7',
  },
  {
    _id: 'create',
    name: 'Создан',
    color: '#e2b33e',
  },
  {
    _id: 'update',
    name: 'Обновлен',
    color: '#67b83b',
  },
]

const tagsOptions = [
  {
    _id: 'developers',
    name: 'Застройщики',
    type: 'info',
    tagType: 'info',
    color: '#909399',
  },
  {
    _id: 'offers',
    name: 'Офферы',
    type: 'info',
    tagType: 'info',
    color: '#909399',
  },
  {
    _id: 'prices',
    name: 'Бюджет на покупку',
    type: 'warning',
    tagType: 'warning',
    color: '#e6a23c',
  },
  {
    _id: 'region',
    name: 'Регион покупки',
    type: 'danger',
    tagType: 'danger',
    color: '#f56c6c',
  },
  {
    _id: 'rooms',
    name: 'Комнатность',
    type: 'primary',
    tagType: '',
    color: '#409eff',
  },
  {
    _id: 'sex',
    name: 'Пол',
    type: 'success',
    tagType: 'success',
    color: '#67c23a',
  },
]

const tagsOrder = [
  //
  'rooms',
  'prices',
  'sex',
  'region',
  'developers',
  'offers',
]

const tagsChanges = {
  rooms: {
    СТУДИЯ: 'Студия',
    '1кк': '1-комнатная',
    '2кк': '2-комнатная',
    '2евро': '2-комн. (евро)',
    '3кк': '3-комнатная',
    '3евро': '3-комн. (евро)',
    '4кк': '4-комнатная',
    '4евро': '4-комн. (евро)',
  },
}

const regionsOrder = [
  //
  'Москва',
  'Подмосковье',
  'Новая Москва',
  'Санкт-Петербург',
  'Казань',
]

const historyOptions = [
  {
    _id: 'decrease',
    color: '#c8c9cc',
  },
  {
    _id: 'increase',
    color: '#67c23a',
  },
  {
    _id: 'create',
    color: '#b3e19d',
  },
  {
    _id: 'read',
    color: '#c8c9cc',
  },
  {
    _id: 'update',
    color: '#f3d19e',
  },
  {
    _id: 'remove',
    color: '#fab6b6',
  },
]

const eventOptions = [
  {
    _id: 'Leads',
    name: 'Лид',
    action: [...actionsOptions],
  },
  {
    _id: 'Offers',
    name: 'Оффер',
    action: [...actionsOptions],
  },
  {
    _id: 'Companies',
    name: 'Компания',
    action: actionsOptions.map((prop) => {
      return { ...prop, name: prop.name + 'а' }
    }),
  },
  {
    _id: 'Users',
    name: 'Пользователь',
    action: [...actionsOptions],
  },
  {
    _id: 'Clients',
    name: 'Клиент',
    action: [...actionsOptions],
  },
]

const clientsOptions = [
  {
    _id: 'sell',
    name: 'На продаже',
  },
  {
    _id: 'refund',
    name: 'Возврат',
  },
  {
    _id: 'refused',
    name: 'Архив',
  },
  {
    _id: 'buy',
    name: 'Купленные',
  },
  {
    _id: 'upload',
    name: 'Загруженные',
  },
  {
    _id: 'create',
    name: 'Созданные',
  },
]
// const initEvents = [
//   ['001', 'url:1001', 'leads', 'delete', 'blabla', false, true, new Date('2023-02-11T22:18:00')],
//   ['002', 'url:1002', 'offers', 'create', 'not notification', false, false, new Date('2023-02-11T20:00:00')],
//   ['003', 'url:1003', 'offers', 'create', 'bmhnlabla', false, true, new Date('2023-02-10T20:21:00')],
//   ['004', 'url:1004', 'leads', 'update', '', false, true, new Date('2023-02-09T14:34:00')],
//   ['005', 'url:1005', 'offers', 'read', '3456ry', false, true, new Date('2023-02-07T12:31:00')],
//   ['006', 'url:1006', 'leads', 'delete', '', false, true, new Date('2023-02-06T11:02:00')],
// ].map(e => new NoteEvent(...e))

const initState = {
  companies: {
    name: '',
    balance: 0,
  },
  users: {
    name: '',
    companyId: '',
    login: '',
    password: '',
    roleId: '',
  },
  offers: {
    companyId: {},
    name: '',
    phone: '',
    price: 0,
    limit: 0,
    state: 'Не активен',
    region: '',
    description: '<p></p>',
    rules: '<p></p>',
    developer: '',
    placemarks: [],
    canRead: [],
    workTime: {
      weekdays: [new Date(2022, 9, 10, 9, 0), new Date(2022, 9, 10, 18, 0)],
      weekends: [new Date(2022, 9, 10, 9, 0), new Date(2022, 9, 10, 18, 0)],
      timebreak: [new Date(2022, 9, 10, 12, 0), new Date(2022, 9, 10, 13, 0)],
    },
  },
  leads: {
    step: 'create',
    status: 'Без статуса',
    phone: '',
    phoneOffer: '',
    price: 0,
    comment: '<p></p>',
    records: [],
    tags: [],
    callAt: new Date(),

    //companyId: "",
  },
}

const pagSizeOpt = [10, 25, 50, 100]

const offerConf = {
  god: {
    state: [
      { text: 'На модерации', value: 'На модерации' },
      { text: 'Активен', value: 'Активен' },
      { text: 'В архиве', value: 'В архиве' },
      { text: 'Не активен', value: 'Не активен' },
    ],
  },
  'god-god': {
    state: [
      { text: 'На модерации', value: 'На модерации' },
      { text: 'Активен', value: 'Активен' },
      { text: 'В архиве', value: 'В архиве' },
      { text: 'Не активен', value: 'Не активен' },
    ],
  },
  admin: {
    state: [
      { text: 'На модерации', value: 'На модерации' },
      { text: 'Не активен', value: 'Не активен' },
    ],
  },
  offerStateColor(c) {
    return {
      'Не активен': 'info',
      Активен: 'success',
      'На модерации': 'warning',
      '   ': 'danger',
    }[c]
  },
}
const leadStatuses = ['Без статуса', 'Целевой', 'Нецелевой', 'Отменен', 'Подтвержден', 'Обрыв связи']
const leadConf = {
  status: leadStatuses,
  statusesWithReject: leadStatuses.concat(['Апелляция']),
  step: [
    { text: 'create', value: 'create' },
    { text: 'upload', value: 'upload' },
    { text: 'sell', value: 'sell' },
    { text: 'buy', value: 'buy' },
  ],
  leadStatusColor(c) {
    return {
      'Без статуса': 'info',
      Подтвержден: 'success',
      Нецелевой: 'warning',
      Отменен: 'danger',
      Целевой: 'primary',
    }[c]
  },
  leadStatusButtonColors(c, a) {
    //при заданном 'a' вернет строку 'rgba([преобразованные значения из hex], a)'
    let colors = {
      'Без статуса': '#9b9a9a',
      Подтвержден: '#67b83b',
      Нецелевой: '#a32cd7',
      Апелляция: '#ab078c',
      Отменен: '#F56C6C',
      Целевой: '#e2b33e',
    }
    let hexToRgbA = (hex, a) => {
      let c
      if (/^#([a-f0-9]{3}){1,2}$/i.test(hex)) {
        c = hex.slice(1).split('')
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = c.map((s, i) => (!(i % 2) ? '0x' + s + c[i + 1] : '')).filter((s) => s)
        return `rgba(${[parseInt(c[0], 16), parseInt(c[1], 16), parseInt(c[2], 16)].join(',')},${a})`
      }
      console.log('Bad Hex')
      return hex
    }
    return a === undefined ? colors[c] : hexToRgbA(colors[c], a)
  },
}

const companyConf = {
  roles: [
    { text: 'Пантеон', value: 'god' },
    { text: 'Рекламная компания', value: 'ad' },
    { text: 'Агенство', value: 'agency' },
    { text: 'Сall-Center', value: 'сallcenter' },
    { text: 'Партнер', value: 'partner' },
  ],
  companiesNamesMap(c) {
    return {
      god: 'Пантеон',
      ad: 'Рекламная компания',
      agency: 'Агенство',
      сallcenter: 'Сall-Center',
      partner: 'Партнер',
    }[c]
  },
}
//TODO сделал 2 массива roles - пока не утрясли
const userConf = {
  roles: [
    { text: 'god', value: 'god' },
    { text: 'admin', value: 'admin' },
    { text: 'manager', value: 'manager' },
  ],
}

const promptOpts = {
  confirmButtonText: 'Да',
  cancelButtonText: 'Нет',
  inputPattern: /.*\\S.*'/,
  inputErrorMessage: 'Yt',
}
const confirmOpts = {
  confirmButtonText: 'Да',
  cancelButtonText: 'Нет',
  type: 'warning',
}
const successUpdateMsg = {
  type: 'success',
  message: 'Обновили',
}

const tableColWidth = {
  selection: 55,
  status: 215,
  callAt: 96,
  companyIdName: 93,
  offerIdName: 100,
  phone: 135,
  price: 85,
  records: 170,
  subject: 150,
  action: 150,
  message: 150,
  link: 150,
}

export {
  devUrl,
  initState,
  leadConf,
  pagSizeOpt,
  offerConf,
  companyConf,
  userConf,
  promptOpts,
  confirmOpts,
  tableColWidth,
  successUpdateMsg,
  layoutSizing,
  // initEvents,
  actionsOptions,
  eventOptions,
  clientsOptions,
  tagsOptions,
  tagsOrder,
  tagsChanges,
  regionsOrder,
  historyOptions,
}
