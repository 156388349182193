import Push from 'push.js'
const icon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAIAAAD8GO2jAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAGjSURBVEhLY9T0K2OgJWCC0jQDNLcAEUTlyT6BziZABh83J0SEVPDp6/f1e88AGZ1zt0BEgIBZVN0aSAFNj/ezZWdjBSKIBBkAqNdAXR6IeLg5jp6/BRGEBhHE7dQCyKZBLSAvWHoWbHNKbndL75y+ci9UCAyQTSM/kltnbZy3/uCLNx+evHg3edkuNDvgYBAn07W7T0NZYLB+Hyj9YALyLfjz9y+UBQZ//vyDslAB+Rb42BtCWWDgZasPZaEC8i1oyQsNcDJmYWbiYGON9rYqSfCCSqACaE6+trETwqcW0PIvhzAGcSoiEoxaQBBgtwBYzhiF1ugFVVZNXPXv/3+oKFkAiwXAMnLp1mM/fv3+8/ffhn1nayathkqQBbBYsO3wRSgLDLYcPA9lkQWwWMDCgiLIwswMZZEFsFgQ6IRSuwW7mkJZZAEsFlAXYLEgM9w5N8pNRkJIQkQgKdC+Os0fKkEWgBZ2J5Y1kN1awQTA9otFVAOEDfUBpD1DLYBsGrRdBGzGABszSjJiP3//IbtpBHT4ih0nLt58hNzwGm38EgAMDAB2G3fd+Ya3hQAAAABJRU5ErkJggg=='

function showNotification(data) {
  console.log(data)
  Push.create('Событие 2vitrina.ru', {
    body: data.message,
    icon: icon,
    timeout: 4000,
    onClick: function () {
      window.focus()
      this.close()
    },
  })
}

export { showNotification }
