import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import axios from 'axios'
import { dayjs } from './services/utils'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import YmapPlugin from 'vue-yandex-maps'
const mapSettings = {
  apiKey: '26c63123-d879-4a26-9556-dd37753c5531',
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1',
}
import { abilitiesPlugin } from '@casl/vue'
import ability from '@/services/ability'
import ru from 'element-plus/dist/locale/ru.js'
import VueTheMask from 'vue-the-mask'

axios.defaults.headers.common['Authorization'] = store.getters.isLoggedIn
const user = store.getters.getUser
const app = createApp(App)
  .use(VueTheMask)
  .use(store)
  .use(router)
  .use(ElementPlus, {
    locale: ru,
  })
  .use(YmapPlugin, mapSettings)
  .use(abilitiesPlugin, ability(user), {
    useGlobalProperties: true,
  })
app.component('QuillEditor', QuillEditor)
app.config.globalProperties.$dayjs = dayjs
app.mount('#app')

axios.interceptors.response.use(undefined, function (error) {
  if (error?.response?.status === 401) {
    store.dispatch('logout')
    return router.push('/login')
  }
  if (error?.response?.status !== 401) return Promise.reject(error)
  if (error) {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      store.dispatch('logout')
      return router.push('/login')
    }
  }
})
