<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</template>

<style>
.bnt-text {
  margin-left: 10px;
}

.el-tabs__nav-next,
.el-tabs__nav-prev {
  background-color: rgb(62 162 247 / 38%);
}

.textcopy-icon {
  margin-left: 5px;
  cursor: pointer;
  vertical-align: middle;
}

.el-message-box__title>span {
  font-size: 16px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .xs-top-margin {
    margin-top: 10px;
  }

  .el-dialog {
    width: 100% !important;
  }
}
</style>