<template>
    <div class="common-layout" style="height: 100%">
        <el-container style="position: relative">
            <!-- <el-aside :style="{ width: !isCollapse ? '200px' : '55px', background: '#f7f7f7' }" :class="isCollapse ? 'collapsed' : ''" height="100vh"> -->
            <el-aside class="aside-cont" height="100%" :width="getViewportSize(['sm', 'md']) ? '65px' :
                getViewportSize('xs') ? '0' : 'min-content'">
                <!-- <el-radio-group v-model="isCollapse" style="margin-bottom: 20px;">
                    <el-radio-button :label="false">Full</el-radio-button>
                    <el-radio-button :label="true">Mini</el-radio-button>
                </el-radio-group> -->

                <!-- <el-menu :default-active="'/' + this.$route.path.split('/')[1]" mode="vertical" :router="true"
                    :style="{ background: '#f7f7f7' }" :collapse="isCollapse" @open="handleOpen" @close="handleClose"> -->
                <div class="aside" :class="{
                    collapsed: isCollapseComp,
                    hided: isHideComp,
                    mobile: isMobileSize,
                    fixed: getViewportSize(['xs', 'sm', 'md']),

                }" style="height: 100vh">
                    <el-menu :default-active="'/' + this.$route.path.split('/')[1]" mode="vertical" class="menu"
                        :router="true" background-color="#294e76" :collapse="isCollapseComp" active-text-color="#fff"
                        text-color="#a0cfff" :collapse-transition="false">
                        <div class="logo" style="margin-left: 20px; margin-right: 20px">
                            <LogoSVG fill="#294e76" color="#fff" :load='loadState' :collapsed="isCollapseComp" />
                        </div>
                        <el-menu-item @click="afterClick" :index="checkUserKey('agency') ? '/clients' : '/main'">
                            <el-icon :size="svgSize">
                                <House style="vertical-align: middle" />
                            </el-icon>
                            <template #title>Главная</template>
                        </el-menu-item>
                        <el-menu-item @click="afterClick" index="/clients"
                            v-if="$can('read', 'Clients') && !checkUserKey('agency')">
                            <el-icon :size="svgSize">
                                <Sell style="vertical-align: middle" />
                            </el-icon>
                            <template #title>Витрина</template>
                        </el-menu-item>
                        <el-menu-item @click="afterClick" index="/leads" v-if="$can('read', 'Leads')">
                            <el-icon :size="svgSize">
                                <Reading style="vertical-align: middle" />
                            </el-icon>
                            <template #title>Лиды</template>
                        </el-menu-item>
                        <el-menu-item @click="afterClick" index="/offers" v-if="$can('read', 'Offers')">
                            <el-icon :size="svgSize">
                                <Document style="vertical-align: middle" />
                            </el-icon>
                            <template #title>Офферы</template>
                        </el-menu-item>
                        <el-menu-item @click="afterClick" index="/users" v-if="$can('read', 'Users')">
                            <el-icon :size="svgSize">
                                <User style="vertical-align: middle" />
                            </el-icon>
                            <template #title>Пользователи</template>
                        </el-menu-item>
                        <el-menu-item @click="afterClick" index="/companies" v-if="$can('create', 'Companies')">
                            <el-icon :size="svgSize">
                                <Collection style="vertical-align: middle" />
                            </el-icon>
                            <template #title>Компании</template>
                        </el-menu-item>
                        <!-- <el-menu-item @click="afterClick" index="/events" v-if="$can('read', 'Companies')"> -->
                        <el-menu-item @click="afterClick" index="/events">
                            <el-icon :size="svgSize">
                                <ChatDotSquare style="vertical-align: middle" />
                            </el-icon>
                            <template #title>Журнал</template>
                        </el-menu-item>
                        <div class="arrow-btn" :class="{ mobile: isMobileSize }"
                            @click="() => isMobileSize ? toggleHide() : toggleCollapse()">
                            <el-icon :class="isCollapseComp ? 'rotated' : ''">
                                <ArrowLeftBold />
                            </el-icon>
                        </div>
                    </el-menu>
                    <div class="menubtn-container" :class="{ mobile: isMobileSize }">
                        <div @click="() => isMobileSize ? toggleHide() : toggleCollapse()">
                            <el-icon :class="isCollapseComp ? 'rotated' : ''">
                                <ArrowLeftBold />
                            </el-icon>
                        </div>
                    </div>
                </div>
            </el-aside>
            <el-container @click="(!isCollapseComp && getViewportSize(['sm', 'md']))
                ? collapseAfterClick()
                : undefined">
                <el-header height="55px">
                    <AppHeader @burger-click="() => isMobileSize ? toggleHide() : toggleCollapse()" @click="testLogout">
                    </AppHeader>
                </el-header>
                <el-main>
                    <router-view v-slot="{ Component, route }">
                        <transition name="fade" mode="out-in">
                            <div :key="route.name">
                                <component :is="Component"></component>
                            </div>
                        </transition>
                    </router-view>
                    <AppFooter />
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.el-container {
    height: 100vh;
    overflow: hidden;
}

.el-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.el-menu {
    border-right: none;
    position: relative;
}

.arrow-btn {
    position: absolute;
    top: 30px;
    right: 20px;
    display: none;
}

.aside {
    width: 200px;
    background: #294e76;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}

.aside.fixed:not(.mobile) {
    transition: width var(--el-transition-duration) var(--el-transition-function-fast-bezier);
}

.aside.collapsed {
    width: 65px;
}

.aside.fixed.collapsed:not(.mobile) {
    transition: width var(--el-transition-duration) var(--el-transition-function-fast-bezier);
}

.aside.fixed {
    position: fixed;
    z-index: 2100;
}

.aside.mobile.fixed {
    transform: translateX(0);
    opacity: 1;
    transition: var(--el-transition-md-fade);
}

.aside.fixed:not(.collapsed, .mobile) {
    box-shadow: var(--el-box-shadow-dark);
}

.aside.mobile.fixed.hided {
    transform: translateX(-100%);
    opacity: 0;
    transition: var(--el-transition-md-fade);
}

.aside.mobile:not(.collapsed) {
    /*position: fixed;
    z-index: 5000;*/
    width: 100%;
    height: 100%;
}



.aside.mobile .el-menu-item .el-icon {
    margin-right: 15px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}

.menu {
    padding-top: 15px;
}

.aside.mobile .menu {
    padding-top: 25px;
}

.aside.mobile .menu>* {
    font-size: 20px;
    margin-bottom: 20px;
}

.aside.mobile .logo {
    margin-bottom: 30px;
}

.aside.mobile .logo svg {
    transform-origin: top left;
    transform: scale(1.5);
}

.menubtn-container {
    display: flex;
    justify-content: center;
    padding: 10px;
    /*margin-bottom: 20px;*/
}

.menubtn-container>*,
.arrow-btn.mobile {
    width: 32px;
    height: 32px;
    /*border: var(--el-border)*/
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0);
    color: #fff;
    cursor: pointer;
    transition: var(--el-transition-all);
}

.menubtn-container>*:hover,
.arrow-btn.mobile:hover {
    /*color: var(--el-color-primary);
    border-color: var(--el-color-primary);*/
    background-color: #4a6c90;
    transition: var(--el-transition-all);
}

.menubtn-container>*>*,
.arrow-btn.mobile>* {
    transform: rotate(360deg);
    transition: var(--el-transition-all);
}

.menubtn-container.mobile {
    display: none;
}

.rotated {
    transform: rotate(180deg);
    transition: var(--el-transition-all);
}
</style>
<style>
.el-collapse-item .el-badge.btn-badge .el-badge__content {
    top: 8px !important;
}

.span-wrap-keepall {
    word-break: keep-all;
    overflow-wrap: break-word;
}
</style>
<script>
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import LogoSVG from '@/components/LogoSVG.vue'
import { layoutSizing } from '@/config.js'
import { getInitWidth, checkUserKey } from '@/services/utils.js'
import axios from 'axios'
import {
    Document,
    House, Reading, User, Collection, Sell, ArrowLeftBold, ChatDotSquare
} from '@element-plus/icons-vue'
import { mapGetters } from 'vuex'
export default {
    components: {
        AppHeader, LogoSVG, Document,
        House, Reading, User, Collection,
        Sell, ArrowLeftBold, ChatDotSquare, AppFooter
    },
    data() {
        return {
            isCollapse: false,
            toggleCollapsed: false,
            isHide: true,
            checkUserKey
        };
    },
    computed: {
        loadState() {
            return this.$store.getters['getLoadState']
        },
        isMobileSize() {
            return this.$store.getters['getWindowSizeState']
        },
        svgSize() {
            return this.$store.getters['getWindowSizeState'] ? 35 : 20
        },
        isCollapseComp() {
            return this.$store.getters['getWindowSizeState'] ? false : this.isCollapse
        },
        isHideComp() {
            return this.$store.getters['getWindowSizeState'] ? this.isHide : false
        },
        ...mapGetters([
            'getViewportSize'
        ])
    },
    methods: {
        // handleOpen(key, keyPath) {
        //     console.log(key, keyPath);
        // },
        // handleClose(key, keyPath) {
        //     console.log(key, keyPath);
        // },
        onResize() {
            const size = getInitWidth(layoutSizing)
            this.$store.commit('SET_SUP_DATA', {
                f: 'isMobileSize',
                data: size === 'xs'
            })
            this.$store.commit('SET_SUP_DATA', {
                f: 'viewportSize',
                data: size
            })
            this.isCollapse = size === 'xs'
        },
        toggleCollapse() {
            if (this.isMobileSize) return;
            // кнопка бургер за пределами боковой панели, 
            // setTimeout не дает закрыть панель сразу же
            if (!this.toggleCollapsed) {
                this.toggleCollapsed = true;
                setTimeout(() => this.toggleCollapsed = false, 10)
            };
            this.isCollapse = !this.isCollapse
        },
        toggleHide() {
            if (!this.isMobileSize) return;
            this.isHide = !this.isHide
        },
        hideAfterClick() {
            if (this.isMobileSize) this.toggleHide()
        },
        collapseAfterClick() {
            if (this.getViewportSize(['sm', 'md']) && !this.toggleCollapsed) this.isCollapse = true;
        },
        afterClick() {
            this.hideAfterClick();
            this.collapseAfterClick();
        },
        testLogout() {
            // this.$store.commit('SET_TOKEN', 'lol')
            // console.log('lol done');
            // axios.defaults.headers.common['Authorization'] = this.$store.getters.isLoggedIn
        }
    },
    created() {
        window.addEventListener('resize', this.onResize);
        this.onResize();
        this.collapseAfterClick();
    }
}
</script>